/**
 * It checks if a string contains any bad words
 * @param {string} [str] - the string to check
 * @returns A boolean value.
 */
export function checkBadWords(str) {
    const badWordRegex = /dm|đm|đ m|d m|địt mẹ|dit me|lol|lừađảo|conchó|trảtiền|mấtdạy|lừa đảo|con chó|trả tiền|mất dạy|lua dao|con cho|tra tien|mat day/g
    return badWordRegex.test(str)
}

export function isValidURL(str) {
    try {
        return new URL(str)
    } catch (error) {
        return false
    }
}

export function getYoutubeID(link) {
    // Kiểm tra xem đường link có chứa từ khóa "embed" hay không
    const isEmbed = link.includes("embed/")

    // Tách ra đoạn cuối cùng của đường link
    const lastSegment = link.split("/").pop()

    if (link.includes("watch")) {
        const videoID = isEmbed ? lastSegment.split("?")[0] : lastSegment.split("=")[1]
        return videoID
    }

    if (link.includes("channel")) {
        return lastSegment
    }

    if (link.includes("user")) {
        return lastSegment
    }

    if (link.includes("playlist")) {
        const playlistID = lastSegment.split("=")[1]
        return playlistID
    }

    if (link.includes("shorts")) {
        return lastSegment
    }

    if (link.includes("livestream")) {
        return lastSegment
    }

    return undefined
}
