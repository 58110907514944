/**
 * It takes a number, rounds it, converts it to a string, and adds commas to it
 * @param {number} number - The number you want to format.
 * @returns A function that takes a number as an argument and returns a string.
 */
export function numberAnimationFormat(number) {
    return number
        ? Math.round(Number(number))
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "0"
}
