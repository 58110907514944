export const catchError = function(data) {
    let errorText = ""
    if (data.message && typeof data.mess === "string" && data.error && typeof data.error === "string") {
        errorText = data.message + "<br>" + data.error
    } else if (data.error && typeof data.error === "object" && Object.keys(data.error).length !== 0) {
        if (data.error.errors && typeof data.error.errors === "object") {
            errorText = handleErrorObject(data.error.errors)
        } else {
            errorText = handleErrorObject(data.error)
        }
    } else if (data.message && typeof data.message === "object" && Object.keys(data.message).length !== 0) {
        if (data.message.error && typeof data.message.error === "object") {
            errorText = handleErrorObject(data.message.error)
        } else {
            errorText = handleErrorObject(data.message)
        }
    } else if (data.mess && typeof data.mess === "string") {
        errorText = data.mess
    } else if (data.message && typeof data.message === "string") {
        errorText = data.message
    } else if (data.error && typeof data.error === "string") {
        errorText = data.error
    } else if (data.res && typeof data.res.error === "string") {
        errorText = data.res.error
    }
    errorText = typeof errorText === "string" && errorText ? translateError(errorText) : null
    return errorText
}
const handleErrorObject = function(error) {
    let errorText = ""
    for (const key in error) {
        if (error.hasOwnProperty(key)) {
            if (Array.isArray(error[key])) {
                if (error[key][0].match(/taken/)) {
                    errorText += key + " đã tồn tại trong hệ thống, vui lòng thử lại" + "\n"
                } else if (error[key][0].match(/valid/)) {
                    errorText += key + " không hợp lệ, vui lòng thử lại" + "\n"
                } else if (error[key][0].match(/not match/)) {
                    errorText += key + " không chính xác" + "\n"
                } else if (error[key][0].match(/required/)) {
                    errorText += "Vui lòng nhập " + key + "\n"
                } else {
                    errorText += error[key][0] + "\n"
                }
            } else if (error[key].match(/taken/)) {
                errorText += key + " đã tồn tại trong hệ thống, vui lòng thử lại" + "\n"
            } else if (error[key].match(/valid/)) {
                errorText += key + " không hợp lệ, vui lòng thử lại" + "\n"
            } else if (error[key].match(/not match/)) {
                errorText += key + " không chính xác" + "\n"
            } else if (error[key].match(/required/)) {
                errorText += "Vui lòng nhập " + key + "\n"
            } else {
                errorText += key + ": " + error[key] + "\n"
            }
        }
    }
    return errorText
}
const translateError = function(text) {
    text = text.replace("Trường number", "Số lượng")
    text = text.replace("Trường days", "Số ngày")
    text = text.replace(
        "We cant find an account with this credentials.",
        "Sai tên đăng nhập hoặc mật khẩu, vui lòng thử lại."
    )
    return text
}
export const getUserIdFacebookFromUrl = function(link) {
    if (link && link.indexOf("profile.php") > -1) {
        var regex = (link + "&").match(/id=(\d*?)&/)
        if (regex.length === 2) {
            window.toastr.success("Đã tự động get ID cho bạn")
            return regex[1]
        } else window.toastr.error("Hãy get ID Facebook tại Findids.net")
    } else window.toastr.error("Hãy get ID Facebook tại Findids.net")
}
export const facebookPostUrlToId = function(url) {
    let result = null
    if (url) {
        if (url.indexOf("facebook.com") < 0) {
            result = url
        } else {
            if (url.indexOf("/share/") > 0) {
                var otherIdShare = url.match(/https:\/\/www.facebook.com\/share\/(.*)/)
                console.log(otherIdShare)
                return "share/" + otherIdShare[1] || url
            }
            var postId = url.match(/(.*)\/posts\/([0-9A-Za-z]{8,})/)
            var photoId = url.match(/(.*)\/photo.php\?fbid=([0-9A-Za-z]{8,})/)
            var photoId2 = url.match(/(.*)\/photo\/\?fbid=([0-9A-Za-z]{8,})/)
            var videoId = url.match(/(.*)\/video.php\?v=([0-9A-Za-z]{8,})/)
            var watchId = url.match(/(.*)\/watch\/\?v=([0-9A-Za-z]{8,})/)
            var watchLive = url.match(/(.*)\/watch\/live\/\?v=([0-9A-Za-z]{8,})/)
            var storyId = url.match(/(.*)\/story.php\?story_fbid=([0-9A-Za-z]{8,})/)
            var linkId = url.match(/(.*)\/permalink.php\?story_fbid=([0-9A-Za-z]{8,})/)
            var storyIdPhone = url.match(/story_fbid=([0-9A-Za-z]{8,})/)
            var otherId = url.match(/(.*)\/([0-9A-Za-z]{8,})/)
            var commentId = url.match(/(.*)comment_id=([0-9A-Za-z]{8,})/)
            result = postId
                ? postId[2]
                : photoId
                ? photoId[2]
                : photoId2
                ? photoId2[2]
                : videoId
                ? videoId[2]
                : watchId
                ? watchId[2]
                : watchLive
                ? watchLive[2]
                : storyId
                ? storyId[2]
                : linkId
                ? linkId[2]
                : storyIdPhone
                ? storyIdPhone[1]
                : otherId
                ? otherId[2]
                : ""
            // if (commentId) {
            //     result += "_" + commentId[2]
            // }
            if (commentId) {
                result = commentId[2]
            }
        }
        window.toastr.success("Đã tự động get ID cho bạn")
        return result
    }
}
export const buildParamQuery = function(params) {
    let esc = encodeURIComponent
    let keyArray = []
    for (const key in params) {
        if (params.hasOwnProperty(key) && (params[key] || params[key] === 0)) {
            keyArray.push(key)
        }
    }
    let query = keyArray.map(k => esc(k) + "=" + esc(params[k])).join("&")
    return query ? "?" + query : ""
}
export const removeUnicode = function(str) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a")
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e")
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i")
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o")
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u")
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y")
    str = str.replace(/đ/g, "d")
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A")
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E")
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I")
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O")
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U")
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y")
    str = str.replace(/Đ/g, "D")
    return str
}
export const formatNumber = function(number) {
    return number
        ? Math.round(Number(number))
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        : 0
}
export * from "@/helpers/number"
export * from "@/helpers/string"
